@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  display: flex;
  align-items: center;
  color: #00d8ff;
}
.navbar img:hover {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

.navbar .links {
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #2f71a1;
  /* color: #00d8ff; */
}
.navbar .navbar__logo {
  display: inline;
  display: initial;
  height: 34px;
  width: 38px;
  margin-left: .5rem;
  max-width: 100%;
  box-sizing: border-box;
}
.content {
  /* display: block; */
  /* max-width: 100%; */
  margin: 0 auto;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

/* Pages Title */
.page-title {
  display: flex;
  padding-bottom: 16px;
  color: #2f71a1;
  /* color: #00d8ff; */
  /* align-items: center; */
  /* text-align: center; */
}
.page-title i {
  align-items: center;
  /* text-align: center; */
  padding-right:8px;
  color: #2f71a1;
  /* color: #88a1a5; */
}


/* Portfolio list Component */
.project-preview {
  padding: 10px 16px;
  margin: 20px 0px;
  border-bottom: 1px solid #fafafa;
  /* border: 0; */
  border-radius: 8px;
  background-color:rgb(0,216,255,0.1);
}
.project-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.project-preview a, .project-details a {
  text-decoration: none;
}
.project-list .project-pic, .project-list .project-vid {
  margin: 0 auto;
  display: block;
  max-height: 450px;
  max-width: 100%;
  /* width: 100%; */
}

/* Portfolio details component */
.project-details {
  /* width: 100%; */
  /* margin: 0 auto; */
}

.project-details .project-pic, .project-details .project-vid {
  /* margin: 0 auto; */
  /* display: block; */
  /* max-height: 450px; */
  max-width: 100%;
  /* width: 100%; */
}
.portfolio-back {
  display: block;
  margin-bottom: 20px;
}
.project-title {
  margin-bottom: 15px;
}
.project-desc {
  margin-bottom: 30px;
}

#cv-svg {
  margin: 20px 0px;
  border: 1px solid #2f71a1;
  /* border: 1px solid #00d8ff; */
  /* border-left: 1px solid #2f71a1;
  border-right: 1px solid #2f71a1; */
  /* border-left: 1px solid #00d8ff;
  border-right: 1px solid #00d8ff; */
}

.download-link {
  display: block;
}
.download-link a {
  /* color: #2f71a1; */
  text-decoration: none;
}
.download-link i {
  color: #2f71a1;
}
.contact-link {
  display: block;
  text-decoration: none;
}
.contact-link i {
  padding-right: 8px;
  margin-bottom: 16px;
}

.contact-list {
  max-width: 100%;
}
